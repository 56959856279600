<template>
  <div class="quote-list pb-5">
    <div v-if="user && user.id">

    
    

      <div class="">
        <div class="row">
         
          <div class="col-sm-12">
            
            <!-- ORDERS LIST | START-->
            <div v-if="!current_quote">
              <h1 class="h3 mb-4">Elenco Preventivi</h1>  
              <div v-if="quotes.data">
                <div class="row mb-4">
                  <div class="col-sm-2">
                    <p class="small">
                      <strong>{{ quotes.total }}</strong> ordini trovati
                      <template  v-if="quotes.total > 0"><br>Pagina <strong>{{ quotes.current_page }}</strong> di <strong>{{ quotes.last_page}}</strong></template>
                    </p>
                  </div>
                  <div class="col-sm-10 d-flex">

                    <!-- FILTERS | START -->
                    <div class="form-group flex-fill pe-2">
                      <label class="small">Stato</label>
                      <select class="form-select form-control-sm" v-model="current_status">
                        <option :value="null">Tutti gli stati</option>
                        <option v-for="st in status_list" :key="st.id" :value="st.id">{{ st.name }}</option>
                      </select>
                    </div>
                    <div class="form-group flex-fill pe-2">
                      <label class="small">A partire dal</label>
                      <input type="date" class="form-control form-control-sm" v-model="date_start" placeholder="Da">
                    </div>
                    <div class="form-group flex-fill pe-2">
                      <label class="small">Fino al</label>
                      <input type="date" class="form-control form-control-sm" v-model="date_end" placeholder="A">
                    </div>
                    <div class="form-group flex-fill pe-2">
                      <label class="small">Ricerca libera</label>
                      <input v-model="search" type="text" class="form-control form-control-sm" placeholder="Nome, indirizzo, etc" v-on:keyup.enter="getQuotes({ search: search })">
                    
                    </div>
                    <button class="btn btn-primary" @click.prevent="getQuotes({ search: search, status: current_status, date_start: date_start, date_end: date_end })">Cerca</button>
                    
                    <!-- FILTERS | END -->
                    <hr>
                  </div>
                </div>
                
                <div class="table-responsive">
                  <table class="table table-striped table-hover" v-if="quotes.data && quotes.data.length > 0">
                    <thead>
                      <tr>
                        <th>Numero Preventivo</th>
                        
                        <th>Nome</th>
                        <th>Negozio</th>
                        <th>Cliente</th>
                        
                        
                        <th>Data Emissione</th>
                        <th>Totale</th>
                        
                        <th>Stato</th>
                        <!-- <th>Fatturato</th> -->
                        <th>Azioni</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="quote in quotes_filtered" v-bind:key="quote.id">
                        <td class="text-center"><span class="badge badge-pill bg-secondary">{{ quote.id }}</span></td>
                        
                        <td><strong>{{ quote.quote_name }}</strong></td>
                        <td><strong class="text-secondary">{{ quote.shop.name }}</strong></td>
                        <td>{{ quote.last_name }} {{ quote.first_name }}</td>
                        
                        <td><small>{{ formatDatetime(quote.created_at ) }}</small></td>
                        <td class="text-end">
                          <span class=" badge badge-pill badge-price bg-dark" v-if="quote.total_final">
                            {{ formatPrice(quote.total_final) }} &euro;
                          </span>
                        </td>
                        
                        <!--
                        <td class="text-center">
                          <i class="bi bi-check-square" v-if="quote.invoiced"></i>
                        </td>
                        -->
                        <td class="text-center">
                          <small v-if="quote.status" class="font-weight-bold">
                          {{ getStatusName(quote.status) }}
                          </small>
                        </td>
                        <td>
                          <router-link :to="'/quotes/' + quote.id" replace @click="selectQuote(quote)" class="btn btn-primary" title="Dettagli"><i class="bi bi-card-checklist"></i></router-link>

                          <button title="Duplica" class="btn btn-secondary ms-2" @click.prevent="cloneQuote(quote)"><i class="bi-files"></i></button>
                        </td>
                      </tr>
                    </tbody>

                  </table>
                </div>
          

                <div class=" row my-5"  v-if="quotes.total > 0">

                  <div class="col-6 col-sm-6">
                    
                  </div>

                  <!--
                  <div class="col-6 col-sm-3">
                    <div class="form-group">
                      <label for="">Totale ivato</label>
                      <input type="text text-right" class="form-control" readonly v-model="orders_total_vat">
                    </div>
                  </div>
                  -->
                    
                    
                  
                  <div class="paginator col-sm-6 text-right">
                    <br>
                    <div class="input-group">

                      <button class="btn btn-outline-secondary" :disabled="quotes.current_page == 1" @click.prevent="getQuotes({ page: 1, search: search, date_start: date_start, date_end: date_end, status: current_status })" type="button"><i class="bi-chevron-double-left" ></i></button>
                      
                      <button class="btn btn-outline-secondary" :disabled="quotes.current_page <= 1" @click.prevent="getQuotes({ page: quotes.current_page - 1, search: search, date_start: date_start, date_end: date_end, status: current_status })" type="button"><i class="bi-chevron-left" ></i></button>
                      
                      <select v-model="quotes.current_page" class="form-select text-center border-secondary" @change="getQuotes({ page: quotes.current_page, search: search, date_start: date_start, date_end: date_end, status: current_status })">
                        <option v-for="p in parseInt(quotes.last_page)" :key="p" :value="p">{{ p }}</option>
                      </select>
                      
                      <button class="btn btn-outline-secondary" :disabled="quotes.current_page >= quotes.last_page"  @click.prevent="getQuotes({ page: quotes.current_page + 1, search: search, date_start: date_start, date_end: date_end, status: current_status })" type="button"><i class="bi-chevron-right"></i></button>
                      
                      <button class="btn btn-outline-secondary" :disabled="quotes.current_page == quotes.last_page"  @click.prevent="getQuotes({ page: quotes.last_page, search: search, date_start: date_start, date_end: date_end, status: current_status })" type="button"><i class="bi-chevron-double-right"></i></button>
                    </div>
                    <small>di {{ quotes.last_page }} pagine - {{ quotes.total ? quotes.total : 0 }} risultati</small>
                  </div>



                </div>


              </div>
              <div class="alert alert-info" v-else>
                Nessun preventivo trovato
              </div>
            </div>
            <!-- ORDERS LIST | END-->



            <!-- ------------------------------------------------------------------------------------------- -->



            <!-- ORDER DETAIL | START-->
            <div v-else>
              
                <header class="row mb-4">
                  <div class="col-sm-10">
                    <h3><span class="badge bg-primary">{{ current_quote.id }}</span> {{ current_quote.quote_name ? current_quote.quote_name : 'Preventivo' }}</h3>
                    
                    <div>
                      
                      <span>Creato il <strong>{{ formatDatetime( current_quote.created_at ) }}</strong></span><br>
                      <span>Ultime modifica <strong>{{ formatDatetime( current_quote.created_at ) }}</strong></span><br>
                      
                    </div>
                  </div>
                  <div class="col-sm-2 text-end">
                    <!-- <a href="#" @click.prevent="current_quote = null">Torna all'elenco</a>-->
                    <router-link to="/quotes" replace @click="current_quote = null">Torna all'elenco</router-link>
                  </div>
                </header>
                <hr>
                
                <div class="row mb-4">
                  <div class="col-sm-6">
                    <strong class="customer-name">{{ current_quote.last_name }} {{ current_quote.first_name }}</strong><br>
                    <span class="customer-contact" v-if="current_quote.email || current_quote.phone">{{ current_quote.email }} {{ current_quote.phone }}</span><br>
                    <span class="customer-address small" v-if="current_quote.address || current_quote.zip_code || current_quote.city || current_quote.district">
                                        {{ current_quote.address }}<br>
                                        {{ current_quote.zip_code }} {{ current_quote.city }} {{ current_quote.district ? ' (' + current_quote.district + ')' : '' }}
                    </span><br>
                    <span class="customer-corpse" v-if="current_quote.corpse_last_name || current_quote.corpse_first_name"><em>{{ current_quote.corpse_last_name  }} {{ current_quote.corpse_first_name  }}</em></span>
                  </div>
                  <div class="col-sm-6">



                    <div class=" row" v-if="userHasRole('shop_manager')">
                      <div class="col-6">
                        <div v-if="current_quote.is_editable">
                          <button class="btn btn-secondary w-100" @click="setQuote( current_quote )">Carica per modifica</button>
                          
                        </div>
                       
                      </div>
                      <div class="col-6">
                        <div class="input-group mb-3 ">
                          <select  class="form-select" v-model="current_quote.status">
                            
                            <option v-for="st in status_list" :value="st.id" :key="st.id">{{ st.name }}</option>
                          </select>
                          <button class="btn btn-primary" type="button" @click="localSaveQuote( current_quote )">Salva</button>
                          
                        </div>
                      </div>

                      <label class="small">Se il preventivo è ancora in stato ATTIVO è possibile caricarlo e procedere con le modifiche/integrazioni</label>
                      
                    </div>
                    
                    
                  </div>  
                  
                </div>


                

                


                

                <div v-if="current_quote.items && current_quote.items.length > 0 ">
                  
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Codice</th>
                        <th>Descrizione</th>
                        <th>Prezzo</th>
                        
                        
                        <th>Quantità</th>
                        <th>Importo</th>
                      </tr>
                    </thead>
                    <tbody v-if="items_by_type.standard && items_by_type.standard.length > 0">
                      <tr  class="bg-light">
                        <td>&nbsp;</td>
                        <td colspan="4"><strong class="small">Prodotti / Servizi</strong>  </td>
                      </tr>
                      <tr v-for="item in items_by_type.standard" v-bind:key="item.id">
                        <td class="text-center"><span :title="item.id" class="badge badge-pill bg-dark">{{ item.external_id }}</span></td>
                        <td>{{ item.description }}</td>
                        <td>
                            <input class="form-control form-control-sm text-end" type="text"  readonly :value="formatPrice(item.price)">
                            <span class="small" v-if="item.price_original" v-show="show_original_prices">Originale: <strong>{{ formatPrice(item.price_original) }} &euro;</strong></span>

                            
                        </td>
                        <td>
                            <input class="form-control form-control-sm text-end" type="text"  readonly :value="item.quantity">
                        </td>
                        
                      
                        <td>
                            <input class="form-control form-control-sm text-end" type="text"  readonly :value="formatPrice(item.row_total)">
                            <span class="small" v-if="item.row_total_original" v-show="show_original_prices">Originale: <strong>{{ formatPrice(item.row_total_original) }} &euro;</strong></span>
                        </td>
                        <!--<td>
                           
                        </td>-->
                      </tr>
                      <tr>
                        <td></td>
                        <td><em>Sconto</em></td>
                        <td></td>
                        <td></td>
                       
                        <td>
                          <div class="input-group">
                            <span class="input-group-text">-</span>
                            <input class="form-control form-control-sm text-end" type="text"  readonly :value="formatPrice(current_quote.discount_amount)">
                          </div>
                          
                        </td>
                      </tr>
                    </tbody>


                    <tbody v-if="items_by_type.advances && items_by_type.advances.length > 0">
                      <tr  class="bg-light">
                        <td>&nbsp;</td>
                        <td colspan="4"><strong class="small">Anticipazioni</strong></td>
                      </tr>
                      <tr v-for="item in items_by_type.advances" v-bind:key="item.id">
                        <td class="text-center"><span :title="item.id" class="badge badge-pill bg-dark">{{ item.external_id }}</span></td>
                        <td>{{ item.description }}</td>
                        <td>
                            <input class="form-control form-control-sm text-end" type="text"  readonly :value="formatPrice(item.price)">
                            <span class="small" v-if="item.price_original" v-show="show_original_prices">Originale: <strong>{{ formatPrice(item.price_original) }} &euro;</strong></span>

                        </td>
                        <td>
                            <input class="form-control form-control-sm text-end" type="text"  readonly :value="item.quantity">
                        </td>
                        
                      
                        <td>
                            <input class="form-control form-control-sm text-end" type="text"  readonly :value="formatPrice(item.row_total)">
                            <span class="small" v-if="item.row_total_original" v-show="show_original_prices">Originale: <strong>{{ formatPrice(item.row_total_original) }} &euro;</strong></span>
                        </td>
                        <!--<td>
                           
                        </td>-->
                      </tr>
                    </tbody>
                    <tfoot>
                      <!--
                      <tr>
                        <td></td>
                        <td><em>Totale parziale</em></td>
                        <td></td>
                        <td></td>
                       
                        <td>
                          <input class="form-control form-control-sm text-end" type="text"  readonly :value="formatPrice(current_quote.total)">
                        </td>
                      </tr>
                      -->
                      
                      <tr class="bg-light">
                        <td></td>
                        <td><strong>TOTALE</strong></td>
                        <td></td>
                        <td></td>
                       
                        <td>
                          <input class="form-control form-control-sm text-end" type="text"  readonly :value="formatPrice(current_quote.total_final)">
                          <span class="small"  v-show="show_original_prices">
                            Originale: <strong>{{ formatPrice(current_quote.total_original) }} &euro;</strong><br>
                            Differenza: <strong>{{ formatPrice(current_quote.total - current_quote.total_original) }} &euro;</strong>
                          </span>
                        </td>
                      </tr>
                    </tfoot>
                  </table>

                  <div class="discount-enabler text-right my-4">

                  
                    <small>
                        <a href="#" v-if="!show_original_prices" @click.prevent="show_original_prices = !show_original_prices">Mostra prezzi originali</a>
                        <a href="#" v-if="show_original_prices" @click.prevent="show_original_prices = !show_original_prices">Nascondi prezzi originali</a>

                    </small>
                  </div>


                </div>
                <div class="alert alert-info" v-else>
                  Nessun prodotto trovato in questo ordine
                </div>


                <div class="row mb-4">
                  <div class="col-sm-6">
                    <h6>Note per l'azienda</h6> 
                    
                    <div class="mb-4" >
                      <textarea readonly class="form-control" rows="5" :value="current_quote.company_notes"></textarea>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <h6>Note per l'utente</h6> 
                    
                    <div class="mb-4" >
                      <textarea readonly class="form-control" rows="5" :value="current_quote.customer_notes"></textarea>
                    </div>
                  </div>
                  
                </div>

                <div class="row">
                  <div class="col-sm-6">


                    <div class="row">
                      <div class="col">
                        <a class="btn btn-primary w-100" target="_blank" :href="getQuotePdfUrl(current_quote)">Scarica PDF</a>
                      </div>
                      <div class="col">
                        <div class="input-group">
                          <input type="text" class="form-control" v-model="customer_email">
                          <button class="btn btn-primary" @click="sendQuoteEmail( current_quote.id, customer_email )">Invia per email</button>
                        </div>
                      </div>
                    </div>
                    


                    
                  </div>
                  <div class="col text-end">
                    <router-link to="/quotes" replace @click="current_quote = null">Torna all'elenco</router-link>
                  </div>
                </div>

                  
            </div>
            <!-- ORDER DETAIL | END-->

            
          </div>
        </div>


        
      </div>
</div>
  </div>
</template>

<script>
// @ is an alias to /src


import CmShopsMixin from '../mixins/CmShopsMixin'


export default {
  mixins: [ CmShopsMixin ],
  name: 'Quotes',
  data: () => {
		return {
      current_quote: null,
      quotes: [],
      status_list: [],

      search: '',
      date_start: null,
      date_end: null,
      current_status: null,
      
      customer_email: '',


      show_original_prices: false,
			
		}
	},
  props: {
    id: {
      type: String,
      required: false,
    }
  },
  mounted: function(){
    var self = this;

    this.getQuoteStatus( function(){
      self.getQuotes({}, function(){

        
        
        if( self.id ){

          var current = self.quotes.data.find((item) => item.id = parseInt(self.id));
          if( current )
            self.current_quote = current;
          else{
            self.current_quote = null;
            self.$router.replace(  '/quotes' );
          }
            
        }
        

      });
    });
    
    
  },
  methods:{
    
    getQuotes: function( params, callback ){
      var self = this;
      this.api.post( 'quote', params )
        .then(function (response) {
          
          if( response.data.quotes ){
            self.notification.success( 'Preventivi caricati correttamente' );
            self.quotes = response.data.quotes;
          }

          if( typeof callback === 'function')
            callback();
          
        })
        .catch(function (error) {
            self.handleApiError( error );
        });
    },

    getQuoteStatus: function( callback ){
      var self = this;
    
      this.api.get( 'quote/getStatusList')
      .then(function (response) {
          if(response.data.status){
            self.notification.success( 'Stati caricati correttamente' );
            self.status_list = response.data.status;
          }

          if( typeof callback === 'function')
            callback();
      })
    },

    selectQuote: function( quote ){
      this.current_quote = quote;
      this.customer_email = quote.email;
      this.getCurrentQuoteItems();
    },

    getCurrentQuoteItems: function(){
      var self = this;

      var url = 'quote/getQuoteItems/' + this.current_quote.id;
      
      this.api.get( url )
      .then(function (response) {
          if(response.data.items){
            self.notification.success( 'Elementi caricati correttamente' );
            self.current_quote.items = response.data.items;
          }
      })
    },

    getStatusName: function( id ){
      
      var status = this.status_list.find( function( item ){
        return item.id == id;
      });

      return status ? status.name : id;
    },

    localSaveQuote: function( quote ){
      var self = this;
      this.saveQuote( quote, function( quote ){
        if( quote ){
          self.current_quote = quote;
        }
      } );

    },

    cloneQuote: function(quote){

      if( this.confirm('Procedere con la duplicazione del preventivo ' + quote.id) ){
        var self = this;

        var url = 'quote/clone/' + quote.id;
        
        this.api.get( url )
        .then(function (response) {
            self.getQuotes();
        })
      }
      
    }

    
    
    
  },
  computed:{
    quotes_filtered: function(){
      return this.quotes.data;
    },

    items_by_type: function(){
      return this.groupQuoteItemsByType( this.current_quote.items );
    },

    
  },
  
}
</script>
