<template>
  <div class="login">
    <div class="col-sm-4 offset-sm-4">
      <div class="card">
        <div class="card-body">
            <div class="form-group mb-2">
                <label for="email">Email</label>
                <input type="text" class="form-control" id="email" v-model="email" >
            </div>

            <div class="form-group mb-2">
                <label for="password">Password</label>
                <input type="password" class="form-control" id="password" v-model="password" >
            </div>

            <div class="mt-3">
                <button class="btn-primary btn w-100" @click.prevent="login">Login</button>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import CmShopsMixin from '../mixins/CmShopsMixin'

export default {
  mixins: [ CmShopsMixin ],
  data: () => {
		return {
			email: null,
			password: null,
		}
	},
  methods:{
      login: function(){

          var self = this;

          var post_data = {
            'email': this.email,
            'password': this.password,
            'scope': 'shops',
          };

          this.api.post( 'login', post_data)
          .then(function (response) {
              self.notification.success( 'Autenticazione effettuata correttamente' );
              
              self.$store.commit( 'handleLoginResponse', response.data );

              self.$router.push('/shop-selection');
              
          })
          .catch(function (error) {
              self.notification.error('Accesso non autorizzato');
          });
      },

  },
}
</script>