<template>
  <div class="quote-editor">
    <div v-if="user.id">
      <h1 class="h2 mb-4">Componi preventivo</h1>
      <div class="card card-body mb-4">
        <div class="row">
          <div class="col-sm-8">
            <h4 v-if="current_step == 0">Dati anagrafici</h4>
            <h4 v-if="current_step == 1">Prodotti / Servizi</h4>
            <h4 v-if="current_step == 2">Anticipazioni</h4>
          </div>
          <div class="col">
            <button class="btn btn-primary w-100" :disabled="current_step < 1" @click="gotoStep('prev')">Indietro</button>  
          </div>
          <div class="col">
            <button class="btn btn-primary w-100" v-if="current_step < (step_count - 1)" :disabled="current_step > (step_count -1)" @click="gotoStep('next')">Avanti</button>  
            
            <a href="#" class="btn btn-primary w-100" v-if="current_step == (step_count - 1)" data-bs-toggle="modal" data-bs-target="#quoteModal">Riepilogo</a>
             
            
          </div>
        </div>
        

      </div>

      <div v-if="current_step == 0">
        <div class="row">
          <div class="col-sm-3 pb-3">
            <div class="form-group">
              <label for="last_name">Cognome*</label>
              <input type="text" required name="last_name" :class="'form-control ' + ( validation_errors.last_name ? ' is-invalid' : '')" v-model="quote.last_name">
            </div>
          </div>
          <div class="col-sm-3 pb-3">
            <div class="form-group">
              <label for="first_name">Nome*</label>
              <input type="text" required name="first_name" :class="'form-control ' + ( validation_errors.first_name ? ' is-invalid' : '')" v-model="quote.first_name">
            </div>
          </div>
        
          <div class="col-sm-3 pb-3">
            <div class="form-group">
              <label for="email">Email<sup>1</sup></label>
              <input type="text" name="email" :class="'form-control ' + ( validation_errors.email ? ' is-invalid' : '')" v-model="quote.email">
            </div>
          </div>
          <div class="col-sm-3 pb-3">
            <div class="form-group">
              <label for="phone">Numero di telefono<sup>1</sup></label>
              <input type="text" name="phone" :class="'form-control ' + ( validation_errors.phone ? ' is-invalid' : '')" v-model="quote.phone">
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6 pb-3">
            <div class="form-group">
              <label for="address">Indirizzo (con numero civico)</label>
              <input type="text" name="address" :class="'form-control ' + ( validation_errors.address ? ' is-invalid' : '')" v-model="quote.address">
            </div>
          </div>
          <div class="col-sm-2 pb-3">
            <div class="form-group">
              <label for="city">Città</label>
              <input type="text" name="city" :class="'form-control ' + ( validation_errors.city ? ' is-invalid' : '')" v-model="quote.city">
            </div>
          </div>
          <div class="col-sm-2 pb-3">
            <div class="form-group">
              <label for="zip_code">CAP</label>
              <input type="text" name="zip_code" :class="'form-control ' + ( validation_errors.zip_code ? ' is-invalid' : '')" v-model="quote.zip_code">
            </div>
          </div>
        
          
          <div class="col-sm-2 pb-3">
            <div class="form-group">
              <label for="district">Provincia</label>
              <input type="text" name="district" :class="'form-control ' + ( validation_errors.district ? ' is-invalid' : '')" v-model="quote.district">
            </div>
          </div>

          <div class="col-sm-3 pb-3">
            <div class="form-group">
              <label for="corpse_last_name">Cognome Salma</label>
              <input type="text" name="corpse_last_name" :class="'form-control ' + ( validation_errors.corpse_last_name ? ' is-invalid' : '')" v-model="quote.corpse_last_name">
            </div>
          </div>
          <div class="col-sm-3 pb-3">
            <div class="form-group">
              <label for="corpse_first_name">Nome Salma</label>
              <input type="text" name="corpse_first_name" :class="'form-control ' + ( validation_errors.corpse_first_name ? ' is-invalid' : '')" v-model="quote.corpse_first_name">
            </div>
          </div>
        </div>
        <hr>
        <small>* Campo obbligatorio - <sup>1</sup> Compilare almeno uno dei campi</small>

      </div>
      <div v-if="current_step == 1">
        <QuoteProductsList type="standard"/>
      </div>

      <div v-if="current_step == 2">
        <QuoteProductsList type="advances"/>
      </div>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src


import CmShopsMixin from '../mixins/CmShopsMixin'
import CatalogImage from '../components/CatalogImage'
import QuoteProductsList from '../components/QuoteProductsList'


export default {
  mixins: [ CmShopsMixin ],
  components: { CatalogImage, QuoteProductsList },
  name: 'QuoteEditor',
  data: () => {
		return {
      current_step: 0,
      step_count: 3,
      validation_errors: {},

			
      /*
      current_quote:{
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        zip_code: '',
        district: '',
        corpse_last_name: '',
        corpse_first_name: '',
      },
      */
      
			
		}
	},
  mounted: function(){
    
    
    
  },
  methods:{
    
    gotoStep: function( destination ){

      if( destination == 'prev' || destination == 'next'){
        if( destination == 'prev' && this.current_step > 0){
          this.current_step--;        
        }

        if( destination == 'next' && this.current_step < this.step_count){

          if( this.current_step == 0){

            var self = this;
            // this.current_step++;
            this.validateQuote( function(){
              self.current_step++;
            });
          }
          else{
            this.current_step++;
          }


        }
      }
      else if( destination >= 0 && destination <= 2){
        if( this.current_step == 0){

          var self = this;
        // this.current_step++;
          this.validateQuote( function(){
            self.current_step = destination;
          });
        }
        else{
          this.current_step++;
        }
      } 

      
    },

    validateQuote: function( callback ){
      var self = this;
      this.api.post( 'quote/validateQuote', { quote: this.quote })
        .then(function (response) {

          
          if( response.data.validated ){
            self.validation_errors = {};
            callback();
          }
          else{
            self.validation_errors = response.data.errors;
          }

        
        })
        .catch(function (error) {
            self.handleApiError( error );
        });
    },
    
    
  },
  computed:{
    
  }
  
}
</script>
