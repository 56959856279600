<template>

    <div  class="quote-products-list">
        <div class="row mb-4">
                
                <div class="col-sm-8">
                  <p class="mt-3">
                    <strong>{{ products.total ? products.total : 0 }}</strong> prodotti trovati 
                    <template  v-if="products.total > 0"> - Pagina <strong>{{ products.current_page }}</strong> di <strong>{{ products.last_page}}</strong></template>
                  </p>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <input v-model="search" type="text" class="form-control" placeholder="Descrizione" v-on:keyup.enter="getShopProducts({ type: type, search: search, order_by: order_by })">
                    <button class="btn btn-primary" @click.prevent="getShopProducts({ type: type,search: search })">Cerca</button>
                  </div>
                </div>
              </div>

              <div class="table-container" v-if="products.data">
                <table class="table table-hover ">
                  <tbody>
                    <tr v-for="product in products.data" v-bind:key="product.id">
                      <td class="col-1 align-middle"><CatalogImage :code="product.external_id" :imgclass="'img-fluid'"/></td>
                      <td class="col-7 align-middle">
                        <span :title="product.id" class="badge badge-pill bg-dark">{{ product.external_id }}</span>
                        <br>
                        <div class="h6 my-3">{{ product.description }}</div>
                      </td>
                      <td class="text-end col-2 align-middle">
                        <div class="input-group">
                            <input type="number" class="form-control text-end" :value="formatPrice(product.current_price)" disabled>
                            <span class="input-group-text">&euro;</span>
                        </div>
                      </td>
                      <td class="align-middle text-end">
                        <!--
                        <a class="btn btn-secondary me-2" target="_blank" :href="'https://docs.coccatoemezzetti.com/pagine/PDF_cat.php?art=' + product.external_id">
                          <i class="bi bi-file-pdf"></i>
                        </a>
                        -->
                        <button  @click="setCurrentProduct( product )" class="btn btn-primary me-2"  data-bs-toggle="modal" data-bs-target="#productModal">
                          <i class="bi bi-info-circle"></i>
                        </button>

                        <button  @click="addItemToQuote( product, type )" class="btn btn-success me-2" >
                          <i class="bi bi-plus-circle"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>

              <div class="row  my-5">
                <div class="col-4">
                    
                    <select v-model="order_by" class="form-control" @change="getShopProducts({ type: type, page: products.current_page + 1, search: search, order_by: order_by })">
                      <option value="description">Descrizione</option>
                      <option value="external_id">Codice</option>
                    </select>
                    <small>Ordina per</small>
                </div>
                <div class="col-4 offset-4 text-end">
                  <div class="paginator"  v-if="products.total > 0">
                    
                      <div class="input-group">
                        <button class="btn btn-outline-secondary" :disabled="products.current_page == 1" @click.prevent="getShopProducts({ type: type, page: 1, search: search, order_by: order_by })" type="button"><i class="bi-chevron-double-left" ></i></button>
                        <button class="btn btn-outline-secondary" :disabled="products.current_page <= 1" @click.prevent="getShopProducts({ type: type, page: products.current_page - 1, search: search, order_by: order_by })" type="button"><i class="bi-chevron-left" ></i></button>
                        <select v-model="products.current_page" class="form-select text-center border-secondary" @change="getShopProducts({ type: type, page: products.current_page, search: search, order_by: order_by })">
                          <option v-for="p in parseInt(products.last_page)" :key="p" :value="p">{{ p }}</option>
                        </select>
                        <button class="btn btn-outline-secondary" :disabled="products.current_page >= products.last_page"  @click.prevent="getShopProducts({ type: type, page: products.current_page + 1, search: search, order_by: order_by })" type="button"><i class="bi-chevron-right"></i></button>
                        <button class="btn btn-outline-secondary" :disabled="products.current_page == products.last_page"  @click.prevent="getShopProducts({ type: type, page: products.last_page, search: search, order_by: order_by })" type="button"><i class="bi-chevron-double-right"></i></button>
                      </div>
                      <small>di {{ products.last_page }} pagine - {{ products.total ? products.total : 0 }} risultati</small>
                    
                  </div>
                </div>
              </div>

              



    </div>
    
</template>

<script>
import { defineComponent } from 'vue'
import CmShopsMixin from '../mixins/CmShopsMixin'

import CatalogImage from '../components/CatalogImage'


export default defineComponent({
    
  name: 'QuoteProductsList',
  mixins: [ CmShopsMixin ],
  components: { CatalogImage },
  props: {
    
    type:{
        type: String,
        required: true,
    }
  },
  data: function(){
      return {
        
        search: '',
        order_by: 'description',
        products:{},


            
      }
  },

  mounted: function(){
    this.getShopProducts({ type: this.type });
  },
  
  methods:{
    getShopProducts: function( post_data ){
      var self = this;
      
      if(post_data == undefined  ){
        if(!post_data)
          var post_data = {};
      
      } 

      post_data.user_id = this.user.id;

      // passo l'id dell'cliente corrente per sapere il prezzo corrente
      
      if(this.user.profiles[0] != undefined){
        post_data.current_price_list_id = this.user.profiles[0].price_list_id;
        post_data.customer_external_id = this.user.external_id;
      }
      

      this.api.post( 'catalog/getShopProducts' , post_data)
      .then(function (response) {
          if(response.data.products){
            self.notification.success( 'Prodotti caricati correttamente' );
            self.products = response.data.products;
          }

         
      })


    },

  },
  computed: {
     
  },
  watch: {
        
  }
})
</script>