<template>
  <div class="shop_selection">
    <div v-if="user.id">

    
    
      <div v-if="userHasRole('shop_manager')">
        <div v-if="shops.length > 0">
          <p>Selezionare il negozio a cui accedere</p>
          <div class="form-group" v-for="shop in shops" :key="shop.id">
            <div class="form-check">
              <input type="radio" :id="'shop_' + shop.id" class="form-check-input" :value="shop" v-model="selected_shop">
              <label :for="'shop_' + shop.id">{{ shop.name }}</label>
            </div>
          </div>

          <div class="form-actions mt-4">
            <button class="btn btn-primary" @click="selectShop">Seleziona</button>
          </div>
          
        </div>
        <div class="alert alert-danger" v-else>
          Impossibile procedere in quanto l'utente corrente non è stato assegnato a nessun negozio.
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import CmMixin from '../../../webapp/src/mixins/CmMixin'
import CmShopsMixin from '../mixins/CmShopsMixin'

export default {
  mixins: [ CmMixin, CmShopsMixin ],
  name: 'ShopSelection',
  data: () => {
		return {
      shops: [],
			selected_shop: null,
		}
	},
  mounted: function(){
    if( this.userHasRole('shop_manager') ){
      this.getShops();
    }
    else if( this.userHasRole('shop_admin') ){
      this.$router.push('/quotes')
    }
    
  },
  methods:{
    getShops: function(){
      var self = this;
      this.api.get( 'user/getShops')
        .then(function (response) {
          self.shops = response.data.shops;

          if(self.shops.length == 1){
            self.selected_shop = self.shops[0];
            self.selectShop();
          }
            
        })
        .catch(function (error) {
            self.handleApiError( error );
        });
    },
    selectShop: function(){
      if( this.selected_shop ){
        
        this.$store.commit( 'setCurrentShop', this.selected_shop );
        this.$router.push('/catalog');
      }
        
    }
  },
  computed:{
    
  }
  
}
</script>
